
import {request} from '@/sujs/http.js'

// 练习数据
export function fetchDetail(id) {
	return request({
		url:'/qu/qu/detail',
		method:"POST",
		data: { id: id }
	})
}
export function nextQu(examId, quId) {
	return request({
		url:'/user/wrong-book/next',
		method:"POST",
		data: { examId: examId, quId: quId }
	})
}

// 首页数据
export function getExamData(data){
	return request({
		url:'/exercise/exercise/paging',
		method:"POST",
		data:data
	})
}


// 获取练习详情
export function praceDetail(data) {
	return request({
		url:'/exercise/exercise/paper-detail',
		method:"POST",
		data:data
	})
}


