<template>
	<div class="exams" :style="{direction: yuyan.now=='hanyu'?'ltr':'rtl'}">
		<el-card class="box-card">
			<template #header>
				<el-row>
					<el-col @click="clickTag(2)" :span="11">
						<el-tag style="text-align: center;height: 50px;line-height: 50px;" class="ml-2 font-commont"
							:type="current==2?'':'info'">
							{{yuyan.index_shouye}}
						</el-tag>
					</el-col>
					<el-col :span="1"></el-col>
					<el-col @click="clickTag(1)" :span="11">
						<el-tag style="text-align: center;height: 50px;line-height: 50px;" class="ml-2 font-commont"
							:type="current==1?'':'info'">
							{{yuyan.index_kaoshi}}
						</el-tag>
					</el-col>
				</el-row>
			</template>

			<div>
				<el-row class="tac">
					<el-col v-if="current==2" :span="4">
						<div>
							<h5 class="mb-2">{{yuyan.kechengxuanze}}</h5>
							<el-menu :style="{textAlign: yuyan.now=='hanyu'?'left':'right'}" @select="chooseType"
								default-active="1" class="el-menu-vertical-demo">
								<el-menu-item index="1">
									<span class="type-class"><span style="font-size: 16px;">🚗</span>
										{{yuyan.xiaoche}}</span>
								</el-menu-item>
								<el-menu-item index="2">
									<span class="type-class"><span style="font-size: 16px;">🚍</span>
										{{yuyan.huoche}}</span>
								</el-menu-item>
								<el-menu-item index="3">
									<span class="type-class"><span style="font-size: 16px;">🚌</span>
										{{yuyan.keche}}</span>
								</el-menu-item>
								<el-menu-item index="4">
									<span class="type-class"><span style="font-size: 16px;">🛵</span>
										{{yuyan.motuoke}}</span>
								</el-menu-item>
							</el-menu>

						</div>
					</el-col>
					<el-col :span="current==1?24:20">
						<el-card style="height: 100%;" shadow="never">
							<div v-if="current == 2">
								<el-button class="font-commont ml-3" style="width: 40%;text-align: center;"
									@click="clickType(1)" :class="currentT==1?'':'heise'" type="primary" plain>
									{{yuyan.kemuyi}}
								</el-button>
								<el-divider direction="vertical" border-style="dashed" />
								<el-button class="font-commont ml-3" style="width: 40%;text-align: center;"
									@click="clickType(2)" :class="currentT==2?'':'heise'" type="warning" plain>
									{{yuyan.kemusi}}
								</el-button>
							</div>

							<!-- 考试 -->
							<div v-if="current == 1" class="exam-card">
								<div v-for="item in exam_data.records">
									<!-- item.type==1&& -->
									<div class="card" @click="startExam(item.id)" v-if="currentT==1" :key="item.id">
										<div class="a" href="https://www.kaoshixing.com/case/c167">
											<div class="img">
												<img class=""
													:src="item.logo==''?'https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3492645057,1429921208&fm=26&gp=0.jpg':'https://admin.rolumapp.com/upload/'+item.logo"
													:alt="yuyan.exam_pic_jiazai">
											</div>
											<div class="title">{{item.title}}</div>
											<div class="content-ellipsis">
												{{item.content}}
											</div>
											<!-- 	<div>
												<el-button type="success" plain  @click="lookGrade(item)">👨 </el-button>
												<el-button type="success" plain>👨 </el-button>
												<el-button plain @click="startExam(item.id)">{{yuyan.exam_start}}</el-button>
												<el-button type="primary" plain>🙍</el-button>
											</div> -->
											<div class="info clearfix">
												<!-- <div class="label">
													医疗健康
												</div> -->
												<!-- <div class="time">{{yuyan.exam_end_time}}: {{item.endTime?item.endTime:yuyan.now=='hanyu'?yuyan.exam_no:'overTime'}}</div> -->
												<!-- 									<div class="time">{{yuyan.exam_end_time}}:
													{{item.endTime?item.endTime:yuyan.exam_no}}</div> -->
											</div>
										</div>
									</div>
								</div>
								<el-empty style="height: 600px;" v-if="!exam_data.records||exam_data.records.length==0"
									description="暂无考试数据" />
							</div>

							<!-- 练习 -->
							<div v-if="current == 2" class="exam-card">
								<div v-for="item in practice_data.records">
									<div class="card" @click="startExam(item.id)" v-if="item.type==1&&currentT==1"
										:key="item.id">
										<div class="a">
											<div class="img">
												<img class=""
													:src="item.logo==''?'https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3492645057,1429921208&fm=26&gp=0.jpg':'https://admin.rolumapp.com/upload/'+item.logo"
													:alt="yuyan.exam_pic_jiazai">
											</div>
											<div class="title">{{item.title}}</div>
											<div class="content-ellipsis">
												{{item.content}}
											</div>
											<!-- 	<div>
												<el-button type="success" plain  @click="lookGrade(item)">👨 </el-button>
												<el-button type="success" plain>👨 </el-button>
												<el-button plain @click="startExam(item.id)">{{yuyan.exam_start}}</el-button>
												<el-button type="primary" plain>🙍</el-button>
											</div> -->
											<div class="info clearfix">
												<!-- <div class="label">
													医疗健康
												</div> -->
												<!-- <div class="time">{{yuyan.exam_end_time}}: {{item.endTime?item.endTime:yuyan.now=='hanyu'?yuyan.exam_no:'overTime'}}</div> -->
												<!-- 									<div class="time">{{yuyan.exam_end_time}}:
													{{item.endTime?item.endTime:yuyan.exam_no}}</div> -->
											</div>
										</div>
									</div>
									<div class="card" @click="startExam(item.id)" v-if="item.type==2&&currentT==2"
										:key="item.id">
										<div class="a">
											<div class="img">
												<img class=""
													:src="item.logo==''?'https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3492645057,1429921208&fm=26&gp=0.jpg':'https://admin.rolumapp.com/upload/'+item.logo"
													:alt="yuyan.exam_pic_jiazai">
											</div>
											<div class="title">{{item.title}}</div>
											<div class="content-ellipsis">
												{{item.content}}
											</div>
											<!-- 	<div>
												<el-button type="success" plain  @click="lookGrade(item)">👨 </el-button>
												<el-button type="success" plain>👨 </el-button>
												<el-button plain @click="startExam(item.id)">{{yuyan.exam_start}}</el-button>
												<el-button type="primary" plain>🙍</el-button>
											</div> -->
											<div class="info clearfix">
												<!-- <div class="label">
													医疗健康
												</div> -->
												<!-- <div class="time">{{yuyan.exam_end_time}}: {{item.endTime?item.endTime:yuyan.now=='hanyu'?yuyan.exam_no:'overTime'}}</div> -->
												<!-- 									<div class="time">{{yuyan.exam_end_time}}:
													{{item.endTime?item.endTime:yuyan.exam_no}}</div> -->
											</div>
										</div>
									</div>
								</div>
								<el-empty style="height: 600px;"
									v-if="!practice_data.records||practice_data.records.length==0"
									description="暂无练习数据" />
							</div>
						</el-card>
					</el-col>
				</el-row>
			</div>



		</el-card>

	</div>
</template>

<script>
	import grade from "../grade/paper.vue"
	import {
		getExamData
	} from '@/api/lianxi.js'
	import {
		getExamAllDatas
	} from '@/api/exam.js'
	export default {
		data() {
			return {
				// 2练习 1考试
				current: 2,
				currentT: 1,



				activeNames: ['1', '2'],
				yuyan: {},
				dialogVisible: false,
				// 考试数据
				exam_data: {},
				// 练习数据
				practice_data: {},
				// 弹框数据
				paperList: [],
				// 应该是页数
				page: {
					current: 1,
					size: 100,
				}
			}
		},
		components: {
			grade
		},
		watch: {
			'$store.state.language_num'(to, from) {
				this.yuyan = this.$language()
			}
		},
		methods: {
			chooseType(e, h) {
				getExamData({
					...this.page,
					params: {
						carType: e
					}
				}).then(res => {
					this.practice_data = res
				})
			},
			clickTag(e) {
				this.current = e
			},
			clickType(e) {
				this.currentT = e
			},
			// 获取考试详情
			getXiangQData(datas) {
				let data = {
					// 错点current不知道是啥
					current: this.score_data.current,
					params: {
						userId: datas.userId,
						examId: datas.examId
					},
					size: 5
				}
				getXiangQData(data).then(res => {
					this.paperList = res.records
				})
			},
			startExam(id) {
				if (this.current == 2) {
					this.$router.replace({
						path: '/practice',
						query: {
							examId: id
						}
					})
				}
				if (this.current == 1) {
					this.$router.push({
						path: 'detail',
						query: {
							examId: id,
							type: 'kaoshi'
						}
					})
				}

			},
			lookGrade(item) {
				this.dialogVisible = true
				// 没此接口
				// this.getXiangQData(item)
			},
			// 
			getExamDatas(e) {
				// this.exam_data = getExamData(data)
				getExamData({
					...this.page,
					params: {
						carType: 1
					}
				}).then(res => {
					this.practice_data = res
				})
				getExamAllDatas(this.page).then(res => {
					this.exam_data = res
				})
			},

		},
		created() {
			this.yuyan = this.$language()
			this.getExamDatas()
		},
	}
</script>

<style lang="scss" scoped>
	.heise {
		color: #000;
	}

	.exam-title {
		font-family: STHupo;
		font-size: 16px;
		color: #747474;
	}

	.el-collapse {
		padding: 0 200px;
		border-radius: 20px;
		// border: #001528 1px dashed;
		margin: 20px;
		background-color: transparent;
	}

	/deep/ .el-collapse-item__wrap {
		background-color: transparent;
	}

	.el-collapse /deep/ .el-collapse-item__header {
		background-color: transparent;
	}

	.exam-card {
		background-color: transparent;
	}

	.el-collapse /deep/ .el-collapse-item__header {
		padding: 10px;
		font-family: 'UKIJ Tuz Tom';
		font-size: 16px;
		color: #3888FA;
		font-weight: bold;
	}

	.el-tag {
		width: 100%;
		text-align: left;
	}

	.banquan {
		line-height: 30px;
		font-size: 12px;
	}

	.el-dialog-div {
		height: 60vh;
		overflow: auto;
		padding: 10px;
	}

	.exams {
		text-align: center;
		padding-top: 15px;
		min-height: 100%;
		background-color: #F1F1F1;

	}

	.exam-card {
		margin-top: 10px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.card {
		// width: 380px;
		// height: 398px;
		width: 250px;
		height: 265px;
		box-shadow: 0 0 10px 0 rgba(95, 103, 227, 0.10);
		border-radius: 4px;
		margin-right: 30px;
		margin-bottom: 30px;
		position: relative;
		background: #fff;
		// float: left;
	}

	.card:hover {
		cursor: pointer;
		background: #f1f1f1;
	}

	.card .a {
		display: block;
		padding: 25px;
	}

	.card .img {
		img {
			width: 150px;
			height: 150px;
		}
	}

	.card .title {
		margin-top: 10px;
		font-size: 16px;
		color: #3B426B;
		margin-bottom: 10px;
		font-weight: 900;
	}

	.card .content-ellipsis {
		font-size: 12px;
		color: #6D717C;
		width: 100%;
		overflow-x: hidden;
		height: 50px;
		margin-bottom: 25px;
	}

	.card .info {
		position: absolute;
		bottom: 10px;
		left: 25px;
		right: 25px;
		font-size: 12px;
	}

	.type-class {
		font-size: 16px;
	}

	.font-commont {
		font-size: 16px;
		font-weight: 900;
	}
</style>
